define("bottomline-execution/localizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "de": {
      "truck": "Wagen",
      "save": "Speichern",
      "description_language": "Deutsch",
      "close": "Schließen",
      "edit_truck": {
        "title": "LKW"
      },
      "shifts": "Dienste",
      "logout": "Abmelden",
      "settings": "Einstellungen",
      "truck_settings": "LKW Einstellungen",
      "trip_stops_for_shift": "Stopps",
      "column": {
        "Site": "Liefermenge",
        "PlannedDeliveryQty": "Geplant",
        "ConfirmedDeliveryQty": "Bestätigt",
        "compartiments": "Fächer",
        "confirmed_volume": "Bestätigt",
        "Temperature": "Temperatur",
        "Density": "Dichte",
        "Odometer": "Kilometerzähler",
        "PlannedQty": "Geplant",
        "ConfirmedQty": "Bestätigt",
        "Status": "Status",
        "Telephone": "Telefon",
        "Material": "Material",
        "CurrentQty": "Auf LKW",
        "CurrentInventory": "Material",
        "ActualInventory": "Aktuell",
        "total_load": "Ladung",
        "driver_remarks": "Bemerkungen",
        "odometer": "Kilometerzähler",
        "delivery_ticket_number": "Lieferscheinnummer",
        "InventoryQty": "ATG Inhalt",
        "AvailableQty": "ATG Freiraum",
        "UsableStorageQty": "Tank kapazität",
        "Compartments": "Fächer",
        "SiteName": "Kundenname",
        "InventoryDT": "ATG Zeit",
        "remarks": "Bemerkungen",
        "TicketNb": "Lieferschein Nr.",
        "Confirmed15Qty": "Bestätigt 15 Gr. Menge",
        "MaterialSeller": "Verkaufer",
        "confirmed15Qty": "15 Grad Bestätigung ",
        "load_id": "Ladenummer ",
        "planned": "Geplant",
        "material": "Material",
        "load_id_short": "Ladenummer",
        "confirmed_weight": "Bestätigtes Gewicht",
        "tankID": "Material",
        "ATG_LastReading": "ATG Zeit",
        "ATG_CurrentQty": "ATG aktuelle Menge",
        "ATG_Ullage": "ATG Freiraum",
        "AverageSalesQty": "Umsatz",
        "RunOutDate": "Zeit trocken",
        "RealRunOutDate": "Zeit leer",
        "Customer": "Kunde",
        "Amount": "Menge",
        "Date": "Zeit",
        "Distance": "Distanz"
      },
      "map": "Karte",
      "remarks": "Bemerkungen",
      "no_remarks": "Keine Bemerkungen",
      "site": "Tankstelle",
      "finished": "Vollendet",
      "trip": "Tur",
      "ticket": "Lieferdokument",
      "report": "Fahrer Bericht",
      "print": {
        "cancel": "Abbrechen",
        "print": "Drücken",
        "select_printer": "Drucker auswählen",
        "settings": "Druckeinstellungen",
        "title": "Drucken",
        "select_a_printer": "Wähl einen Drucker",
        "margin_top": "Druckrand oben",
        "repeat": "Wiederholungsdruck",
        "preview": "Druck Voschau",
        "connected_with": "Verbunden"
      },
      "details": "Details",
      "material_on_truck": "Material auf LKW",
      "domain": "Domain",
      "language": "Sprache",
      "add_language": "Sprache hinzufügen",
      "description": "Umschreibung",
      "add": "Hinzufügen",
      "edit_languages": "Sprache bearbeiten",
      "select_trip_stop": "Lage auswählen",
      "actions": "Weitere Aktionen",
      "add_stop_from_site": "Anlieferung hinzufügen",
      "add_special_activity": "Aktivität hinzufügen",
      "start": "Anfangen",
      "end_inventory": {
        "title": "LKW Endbestand"
      },
      "materials": "Materialen ",
      "truck_is_empty": "LKW ist leer",
      "extra_information": "Weitere Informationen",
      "restart": "Neustart",
      "planned": "Geplant",
      "register_at_15_d": "Menge ab 15 Grad",
      "confirm": "Bestätigen",
      "cancel": "Stornieren",
      "take_picture": "Foto machen",
      "view_pictures": "Bild anschauen",
      "load": {
        "title": "Ladung"
      },
      "unload": {
        "detail_information": "Einzelheiten"
      },
      "delivery_truck_information": "Lieferwagen Informationen",
      "delivery": "Anlieferung",
      "show_inventory": "Telemetrie anschauen",
      "sites": "Kunden",
      "detail_information": "Details",
      "storages": "Tanks",
      "extra_time": "Extra Zeit",
      "clear": "Stornieren",
      "add_trip_stop": "Lieferung zufügen",
      "tank": "Tank",
      "finish": "Ende",
      "special_activity": {
        "create": "Besondere Aktivität"
      },
      "truck_setting": {
        "settings": "Truck settings (application names)",
        "compartments": "compartments",
        "delivery_confirmation": "delivery_confirmation",
        "load_by_weight": "load_by_weight",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Extra Kunde zufugen",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "save": "Bestätigen ",
        "cancel": "Abbrechen",
        "material_transfer": "material_transfer",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "scanner_enabled": "Scanner aktiviert",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "enable_ocr": "enable_ocr",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "use_material_calculation": "use_material_calculation",
        "authorize_trip_stop": "authorize_trip_stop",
        "inventory_in_percentage": "inventory_in_percentage",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "sening_enabled": "sening_enabled",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "use_access_point": "use_access_point",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "warnings": {
        "title": "Warnung",
        "are_you_sure": "Bist du sicher?"
      },
      "username": "Login Name",
      "password": "Passwort",
      "please_sign_in": "Bitte einloggen",
      "login": "Anmeldung",
      "form_incomplete": "Daten ungültig",
      "cannot_retrieve_data": "Können die Daten nicht abrufen",
      "cannot_open_file": "Daten kann nicht geöffnet werden",
      "connection_error": "Verbindungsfehler",
      "hidden_translations": "Hidden translations",
      "save_location_to_site": "Speicher Anlage zu GPS",
      "detail": "Detail",
      "details_trailer": "Fächer",
      "Load ID": "Ladenummer",
      "scan": "Scan",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Änderungen von Server abrufen"
      },
      "trip-stops": {
        "authorizer_initials": "Initialen"
      },
      "show_unplanned_trip_stop_storages": "Nicht geplant Material",
      "use_bx": "Gebrauch BX",
      "setup_scanner": "Konfiguriere scanner",
      "brand": "Marke",
      "scanner_ssid": "SSID",
      "scanner_password": "Passwort",
      "test_and_connect": "Testen und verbinden",
      "adr_report": "ADR Report",
      "tanks": "Tanks",
      "scan_cancel": "Abbrechen",
      "remarks_for_trip": "Bemerkungen",
      "fill_it": "Füllen",
      "started": "Gestartet",
      "skip": "Skip",
      "scan_title": "Dokument scannen",
      "scan_retry": "Scan",
      "ocr_validation": "OCR-Validierung",
      "add_stop_from_order": "Ab Bestellung hinzufügen",
      "change_truck": "LKW wechseln",
      "trip_stops_for_trip": "Tripstop zum trip",
      "show_trip_stop_storages": "Trip Stop Storages",
      "alias": " ",
      "use_weight": "Gewicht",
      "show_filtered_materials": "zeige",
      "show_all_materials": "zeige alles",
      "show_filtered_tanks": "zeige",
      "show_all_tanks": "zeige alles",
      "storage": {
        "ATG_LastReading": "ATG Zeit",
        "ATG_CurrentQty": "ATG Inhalt",
        "ATG_Ullage": "ATG Freiraum"
      },
      "problem-atg-sites": {
        "hideDetails": "Details",
        "showDetails": "details"
      },
      "problem_atg_sites": "Problematische ATG Tankstellen",
      "site_load_data": "Tankstellendaten",
      "no_materials_on_truck": "Kein Material auf LKW",
      "orders": "Bestellungen",
      "selected_order": "Gewählte Bestellung",
      "no_order_selected": "Kein Bestellung gewählt",
      "material": "Material",
      "amount": "Menge",
      "cannotCalculate": "-",
      "client": "Kunde",
      "min_order_qty": "Menge",
      "order_material_name_short": "Material",
      "order_date": "Liefertermin"
    },
    "fr": {
      "login": "Connexion",
      "logout": "Déconnexion",
      "login_failed": "Connexion échouer",
      "truck": "Truck",
      "fill_it": "Remplir",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Synchronisation"
      },
      "trip_stop_storages": "Tripstop tanks",
      "username": "Utilisateur",
      "password": "Mot de passe",
      "please_sign_in": "Se connecter",
      "settings": "Paramètres",
      "domain": "Domaine",
      "language": "Langue",
      "save": "Sauver",
      "shifts": "Poste",
      "finish": "Terminé",
      "finished": "Fini",
      "truck_settings": "Paramètres du camion",
      "detail_information": "Détails",
      "details_trailer": "Details Citerne",
      "tanks": "Reservoirs",
      "tank": "Reservoir",
      "pause": "Pause",
      "start": "Debut",
      "restart": "Recommencer",
      "map": "Carte",
      "trip_stops_for_shift": "Livraison de cette tour",
      "trip": "Trajet",
      "ticket": "Billet",
      "report": "Rapport",
      "site": "Site",
      "sites": "Sites",
      "details": "Détails",
      "planned": "Planifié",
      "remarks": "Remarques",
      "no_remarks": "Pas de remarques",
      "material_on_truck": "Stock actuel",
      "select_trip_stop": "Sélectionner livraison",
      "actions": "Actions",
      "add_stop_from_site": "Ajouter arrêt de voyage",
      "materials": "Produits",
      "materials_in_weight": "Matériel de poids",
      "save_location_to_site": "Enregistrer localisation actuelle",
      "form_incomplete": "Formulaire invalide",
      "view_pictures": "Voir images",
      "confirm": "Confirmer",
      "cancel": "Annuler",
      "take_picture": "Photo",
      "extra_information": "Informations supplémentaires",
      "use_weight": "Utiliser poids",
      "register_at_15_d": "Enregister 15 deg.",
      "delivery": "Livrasion",
      "delivery_truck_information": "Informations de camion de livraison",
      "storages": "Stockage",
      "add_trip_stop": "Ajouter arrêt de voyage",
      "load": {
        "title": "Charge"
      },
      "end_inventory": {
        "title": "Inventory Fin"
      },
      "edit_truck": {
        "title": "Sélectionnez camion"
      },
      "unload": {
        "title": "Décharger",
        "detail_information": "Détails"
      },
      "column": {
        "TruckID": "Camion ID",
        "TrailerID": "Bande ID",
        "ResourceID": "Ressource ID",
        "ExtSiteID": "Site ID",
        "OrderID": "Ordre ID",
        "ActualInventory": "Inventaire réelle",
        "CurrentInventory": "Inventaire actuel",
        "Telephone": " ",
        "Address": "Addresse",
        "delivery_ticket_number": "Numéro de ticket de livraison",
        "InventoryDT": "Date de l'inventaire",
        "UsableStorageQty": "Capacité",
        "InventoryQty": "Jaugeage actuel",
        "AvailableQty": "Creux actuel",
        "Name": "Nom",
        "PlannedDeliveryQty": "Planifié",
        "PlannedQty": "Planifié",
        "planned": "Planifié",
        "start": "Stock initial",
        "ConfirmedQty": "Confirmé",
        "Status": "Statut",
        "ConfirmedDeliveryQty": "Confirmé",
        "confirmed_volume": "Charge confirmée",
        "total_load": "Charge total",
        "Material": "Produit",
        "material": "Produit",
        "CurrentQty": "Stock",
        "Site": "Site",
        "driver_remarks": "Remarques",
        "load_id": "Numero de chargement",
        "odometer": "KM",
        "Temperature": "Température",
        "Density": "Densité",
        "confirmed15Qty": "Confirmé (15)",
        "confirmed_weight": "Poids confirmé",
        "StartInventoryQty": "Stock initial",
        "AvailableStorageQty": "Stockage max",
        "SiteName": "Nom du site",
        "MaterialSeller": "Client",
        "Confirmed15Qty": "Confirmée 15 deg.",
        "TicketNb": "Numéro de livraison",
        "compartiments": "Compartiments",
        "Compartments": "Compartiments",
        "remarks": "Remarques",
        "tankID": "Cuve",
        "ATG_LastReading": "Date/Heure",
        "ATG_CurrentQty": "Jaugeage",
        "ATG_Ullage": "Creux",
        "OrderQty": "Commandé",
        "load_id_short": "Charge",
        "tank-on-site": "Cuve(s)",
        "Loaded": "A Charger",
        "PlannedOrConfirmed": "Planifié ou Chargé",
        "Corrected": "Corrigé",
        "LeftOver:": "Retour à Bord",
        "LeftOver": "Retour à Bord",
        "SiteAvailableQty": "CREUX",
        "StartsWith": "Volume à Bord",
        "currentTruckInventory": "Volume Chargé",
        "plannedOrConfirmedForSelected": "Volume à livrer",
        "startInventoryQty": "Inventaire avant Liv"
      },
      "print": {
        "cancel": "Annuler",
        "back": "Retourner",
        "title": "Imprimer",
        "print": "Imprimer billet",
        "preview": "Aperçu",
        "settings": "Paramètres",
        "select_printer": "Sélectionnez le périphérique",
        "margin_top": "Lignes de haut"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Ajouter extra livraison",
        "save": "Sauver",
        "cancel": "Annuler",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "material_transfer": "material_transfer",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "use_material_calculation": "use_material_calculation",
        "delivery_confirmation": "delivery_confirmation",
        "questionaire": "questionaire",
        "add_special_activity": "add_special_activity",
        "authorize_trip_stop": "authorize_trip_stop",
        "inventory_in_percentage": "inventory_in_percentage",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "enable_ocr": "enable_ocr",
        "sening_enabled": "sening_enabled",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "use_access_point": "use_access_point",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "description_language": "Francais",
      "add_special_activity": "Ajouter activité spéciale",
      "clear": "Enlever",
      "Load ID": "Référence de chargement",
      "show_unplanned_trip_stop_storages": "Produit non planifié",
      "scan": "Scanner",
      "show_inventory": "Stock actuel",
      "hidden_translations": "Hidden translations",
      "warnings": {
        "title": "Attention",
        "are_you_sure": "Êtes-vous sûr?"
      },
      "adr_report": "ADR Report",
      "remarks_for_trip": "Remarques",
      "order_remarks": "Remarques",
      "scan_cancel": "Annuler",
      "scan_select_scanner": "SSID",
      "start_scan": "Scanner",
      "scan_title": "SCAN",
      "scan_settings ": "Paramètres",
      "scan_preview": "Exemple du scan",
      "started": "Démarrer",
      "scan_retry": "Réessayer",
      "scan_confirm": "Confirm",
      "skip": "Skip",
      "scanner_enabled": "scanner_enabled",
      "truck_is_empty": "camion vide",
      "ocr_validation": ".ocr_validation",
      "trip_stops_for_trip": "Details Tournée",
      "change_truck": "Change Camion",
      "CHANGE_TRUCK": "Change Camion",
      "Change_Truck": "Change Camion",
      "load_as_planned": "Plan = Chargé",
      "filter_compartments": "Filtre Compartiments",
      "trip-stops": {
        "site_extsiteid": "Num site",
        "site_name": "Nom site",
        "site_address": "Adresse",
        "site_city": "CP_Ville",
        "misc_data": "Données",
        "authorize_retail_site": "Site",
        "date_time": "Date",
        "truck_id": "Num contrat",
        "trailer_id": "Num citerne",
        "site_customerid": "Num site",
        "driver_id": "Num conducteur",
        "ext_order_id": "Num commande",
        "authorizer": "Receptionnaire",
        "authorizer_name": "Prenom",
        "authorizer_sur_name": "Nom",
        "customer-signature": "Signature Receptionnaire",
        "driver-signature": "Signature Conducteur",
        "authorize": "Autoriser",
        "disclaimer_body": "Suzanne will add text here",
        "Authorize": "Autoriser",
        "pre_authorization_disclaimer": "Je certifie avoir communiqué au conducteur le document de creux en tenant compte du limiteur d’emplissage et du stock réel et avoir communiqué les consignes de déchargement (creux disponible par cuve, le souhaite de livraison cuve par cuve).",
        "post_authorization_disclaimer": "Je certifie avoir vérifié les informations indiquées sur la tablette électronique et contrôlé les produits et les quantités livrés dans les cuves indiquées sur le bon de déchargement."
      },
      "storage": {
        "ATG_LastReading": "Date/Heure ",
        "ATG_CurrentQty": "Jaugeage",
        "ATG_Ullage": "Creux"
      },
      "auth-form": {
        "delivered-compartment-quantities": "Plan Dechargement",
        "predelivery-tss-quantities": "DOCUMENT DE CREUX"
      },
      "compartment": {
        "label": "Compartiment",
        "currentQty": "Chargée",
        "loadedQty": "Chargée",
        "confirmedQty": "Livré"
      },
      "trip-stop": {
        "dischargePlan": {
          "open": "Plan Déchargement",
          "unavailable": "Indisponible, réessayez dans un instant"
        },
        "documents": {
          "dialogText": "Documents Disponibles",
          "DeliveryTicket": "Bon de livraison",
          "DischargePlan": "Plan de Déchargement",
          "notfound": "Aucun document disponible",
          "error": " Erreur",
          "delivery": "Bon de Livraison",
          "dischargeplan": "Plan Dechargement",
          "deliveryticket": "Bon de Livraison"
        }
      },
      "trip-stop-storage": {
        "endInventoryQty": "Inventaire après Liv",
        "ullage": "Creux après Livraison",
        "confirmedQty": "Quantité confirmée",
        "plannedQty": "Quantité planifiée",
        "MaterialNameShort": "Produit",
        "postDipUllage": "Creux après Livraison"
      },
      "trip_stops": {
        "Authorize": "Autoriser"
      },
      "invalid_alias_or_domain": "alias non valide",
      "connection_error": "Erreur de connexion",
      "cannot_open_file": "Ne peut pas ouvrir le fichier",
      "cannot_retrieve_data": "Impossible de récupérer des données",
      "details_truck": "Details Contrat",
      "postdip_validation": {
        "title": "Jaugeage incorrect",
        "warning": "Vérifier la ou les cuves:",
        "cancel": "Annuler et vérifier données",
        "confirm": "Confirmer les données"
      },
      "alias": " ",
      "current_trailer_inventory": "Remorque",
      "current_truck_inventory": "Truck",
      "trips-stops": {
        "authorize": "Autoriser"
      },
      "form_odometer": "Entrer KM",
      "start_of_shift": "DEBUT POSTE",
      "end_of_shift": "FIN POSTE",
      "ADD_STOP_FROM_ORDER": "Ajouter une commande",
      "SHOW_TRIP_STOP_STORAGES": "CUVES A CHARGER",
      "show_trip_stop_storages": "CUVES A CHARGER",
      "add_stop_from_order": "Ajouter une commande",
      "SITE_LOAD_DATA": "INVENTAIRES",
      "site_load_data": "INVENTAIRES",
      "MATERIAL_CALCULATION": "VOLUME PLANIFIE",
      "material_calculation": "VOLUME PLANIFIE",
      " trip-stop": {
        "documents": {
          "discharge": "Plan de Déchargement"
        }
      },
      "reset": "Reset"
    },
    "es": {
      "login": "Iniciar sesión",
      "logout": "Cerrar sesión",
      "login_failed": "Connexion échouer",
      "fill_it": "Rellenar",
      "truck": "Camión",
      "error": {
        "info": "Inicio de sesion fallido"
      },
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Sincronizar"
      },
      "trip_stop_storages": "Tanques",
      "no_remarks": "No hay comentarios",
      "username": "Usuario",
      "password": "Contraseña",
      "please_sign_in": "Por favor registrarse",
      "settings": "Ajustes",
      "domain": "Dominio",
      "language": "Lenguaje",
      "sites": "Lugares",
      "save": "Guardar",
      "save_location_to_site": "Corregir info GPS",
      "shifts": "Servicio",
      "finish": "Terminar",
      "truck_settings": "Ajustes de camión",
      "finished": "Terminar",
      "detail_information": "Detalles",
      "details_trailer": "Detalles trailer",
      "tanks": "Tanques",
      "storages": "Cisternas",
      "tank": "Tanque",
      "add_trip_stop": "Añadir",
      "pause": "Descansar",
      "start": "Iniciar",
      "restart": "Reiniciar",
      "map": "Mapa",
      "trip_stops_for_shift": "Paradas del viaje seleccionado",
      "trip": "Viaje",
      "ticket": "Albarán",
      "report": "Resumen",
      "site": "Lugar de (des)carga",
      "details": "Detalles",
      "planned": "Planificado",
      "remarks": "Comentarios",
      "material_on_truck": "Productos",
      "select_trip_stop": "Selection parada",
      "actions": "Acciones",
      "materials": "Productos",
      "confirm": "Confirmar",
      "view_pictures": "Ver imágenes",
      "take_picture": "Toma foto",
      "extra_information": "Información extra",
      "use_weight": "Introducir en kg",
      "materials_in_weight": "Introducir en kg",
      "register_at_15_d": "Introducir 15grados",
      "cancel": "Cancelar",
      "add_stop_from_site": "Añadir Parada",
      "delivery_truck_information": "Productos disponibles",
      "close": "Cerrar",
      "delivery": "Entrega",
      "form_incomplete": "Entrada incompleta",
      "load": {
        "title": "Detalles de carga"
      },
      "end_inventory": {
        "title": "Disponibilidad final"
      },
      "edit_truck": {
        "title": "Seleccionar camión"
      },
      "unload": {
        "title": "Detalles del lugar",
        "detail_information": "Informacion del cliente"
      },
      "column": {
        "Temperature": "Temperatura",
        "TruckID": "Número de camiones",
        "TrailerID": "Número del remolque",
        "ResourceID": "Chófer",
        "ExtSiteID": "Número de descarga",
        "OrderID": "Número de orden",
        "Address": "Dirección",
        "Telephone": "Teléfono",
        "total_load": "Carga total",
        "load_id": "Numero albarán",
        "Status": "Estado",
        "InventoryDT": "Nivel registrada",
        "UsableStorageQty": "Cant. maxima",
        "InventoryQty": "Nivel",
        "AvailableQty": "Cap. disponible",
        "Name": "Nombre",
        "SiteName": "Nombre del cliente",
        "PlannedDeliveryQty": "Cant. planificada",
        "PlannedQty": "Cant. planificada",
        "CurrentInventory": "Cant. disponible",
        "ConfirmedQty": "Cant. real",
        "Confirmed15Qty": "Cant. 15gr real",
        "CurrentQty": "Cant. inicial",
        "ActualInventory": "Cant. actual",
        "ConfirmedDeliveryQty": "Cant. real",
        "Material": "Producto",
        "Site": "Lugar de (des)carga",
        "driver_remarks": "Comentarios",
        "Density": "Densidad",
        "odometer": "Kilometraje",
        "confirmed15Qty": "Carga real 15g",
        "confirmed_volume": "Carga real",
        "confirmed_weight": "Carga real en kg",
        "delivery_ticket_number": "Numero albarán",
        "MaterialSeller": "Vendedor del material",
        "planned": "Planificado",
        "start": "Inventario inicial",
        "material": "Producto",
        "StartInventoryQty": "Inventario inicial",
        "AvailableStorageQty": "Capacidad Maxima",
        "TicketNb": "Numero de albarán",
        "compartiments": "Compartimento",
        "Compartments": "Compartimento",
        "remarks": "Comentarios",
        "load_id_short": "LoadID"
      },
      "print": {
        "cancel": "Cancelar",
        "back": "Volver",
        "title": "Impresión",
        "print": "Imprimir",
        "preview": "Ejemplo",
        "settings": "Ajustes",
        "select_printer": "Seleccione la impresora",
        "margin_top": "Salto de línea",
        "select_a_printer": "Seleccionar impresora",
        "connected_with": "Conectado",
        "distance": "Distancia",
        "repeat": "Repetir"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Añadir mas paradas en la ruta",
        "save": "Guardar",
        "cancel": "Cancelar",
        "material_transfer": "material_transfer",
        "delivery_confirmation": "delivery_confirmation",
        "questionaire": "questionaire",
        "add_special_activity": "add_special_activity",
        "authorize_trip_stop": "authorize_trip_stop",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "scanner_enabled": "Aplicación de escaner activado",
        "inventory_in_percentage": "inventory_in_percentage",
        "sening_enabled": "sening_enabled",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "enable_ocr": "enable_ocr",
        "use_access_point": "use_access_point",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "use_material_calculation": "use_material_calculation",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "description_language": "Español",
      "adr_report": "ADR Report",
      "use_bx": "Usar BX",
      "setup_scanner": "Instalar escaner",
      "add_language": "Añadir lenguaje",
      "description": "Descripción",
      "add": "Añadir",
      "edit_languages": "Editar Lenguajes",
      "hidden_translations": "Hidden translations",
      "Load ID": "ID de la carga",
      "scan": "Escanear",
      "show_unplanned_trip_stop_storages": "Materiales no planeados",
      "scan_retry": "Escanear de nuevo",
      "scan_title": "Escaner",
      "  column": {
        "compartiments": "Compartimento"
      },
      " column": {
        "Compartments": "Compartimento",
        "remarks": "Comentarios"
      },
      " print": {
        "select_a_printer": "Seleccionar impresora",
        "repeat": "Repetir"
      },
      "add_special_activity": "Actividad especial",
      "clear": "Borrar",
      "show_inventory": "Ultimo inventario",
      "warnings": {
        "title": "Atención",
        "are_you_sure": "Estas seguro?"
      },
      "remarks_for_trip": "Comentarios",
      "order_remarks": "Comentarios",
      "scan_cancel": "Anular",
      "brand": "Marca",
      "scanner_ssid": "SSID",
      "scanner_password": "Contraseña",
      "test_and_connect": "Conectar",
      "empty_compartments": "Vaciar compartimentos",
      "cannot_retrieve_data": "No puede recuperar datos",
      "cannot_open_file": "No puede abrir el archivo",
      "connection_error": "Error de conexión",
      "trip-stops": {
        "authorizer_initials": "Iniciales"
      },
      "skip": "Omitir",
      "truck_is_empty": "Camión vacio",
      "scan_confirm": "Confirmar escaner",
      "started": "Comencé",
      "ocr_validation": ".ocr_validation",
      "alias": " "
    },
    "nl": {
      "login": "Aanmelden",
      "logout": "Afmelden",
      "login_failed": "Inloggen mislukt",
      "truck": "Wagen",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Gegevens vernieuwen"
      },
      "sites": "Locaties",
      "trip_stop_storages": "Tripstop tanks",
      "username": "Gebruikersnaam",
      "password": "Wachtwoord",
      "please_sign_in": "Gelieve aan te melden",
      "settings": "Instellingen",
      "truck_settings": "Truck instellingen",
      "domain": "Domein",
      "language": "Taal",
      "save": "Opslaan",
      "shifts": "Diensten",
      "fill_it": "Vul",
      "finish": "Einde",
      "finished": "Afgerond",
      "restart": "Herstart",
      "resume": "Doorgaan",
      "tanks": "tanks",
      "tank": "tank",
      "pause": "Pauze",
      "start": "Start",
      "map": "kaart",
      "trip_stops_for_shift": "Geplande stops",
      "trip": "Rit",
      "ticket": "Ticket",
      "report": "Rapport",
      "site": "Locatie",
      "details": "Details",
      "planned": "Gepland",
      "remarks": "Opmerkingen",
      "no_remarks": "Geen opmerkingen",
      "material_on_truck": "Materiaal op truck",
      "select_trip_stop": "Selecteer een trip-stop",
      "actions": "Acties",
      "add_stop_from_site": "Ongeplande lossing toevoegen",
      "materials": "Materialen",
      "materials_in_weight": "Materialen op gewicht",
      "save_location_to_site": "Huidig locatie vastleggen",
      "form_incomplete": "Formulier onvolledig",
      "view_pictures": "Bekijk foto's",
      "confirm": "bevestig",
      "cancel": "annuleer",
      "take_picture": "Foto nemen",
      "extra_information": "Extra informatie",
      "use_weight": "Gebruik gewicht",
      "register_at_15_d": "15 graden liters",
      "delivery": "Levering",
      "delivery_truck_information": "Levering truck informatie",
      "storages": "Tanks",
      "add_trip_stop": "Rit-stop toevoegen",
      "detail_information": "Details",
      "details_trailer": "Trailer",
      "load": {
        "title": "Lading"
      },
      "end_inventory": {
        "title": "Eindvoorraad beheren"
      },
      "edit_truck": {
        "title": "Selecteer truck"
      },
      "unload": {
        "title": "Lossen",
        "detail_information": "Details"
      },
      "column": {
        "compartiments": "Compartimenten",
        "TruckID": "Trekker nr",
        "TrailerID": "Oplegger ID",
        "ResourceID": "Chauffeur",
        "ExtSiteID": "Losplaats nummer",
        "OrderID": "Order nummer",
        "CurrentInventory": "Werkelijke voorraad",
        "ActualInventory": "Actuele voorraad",
        "Address": "Adres",
        "Telephone": "Telefoon",
        "delivery_ticket_number": "Losbon nummer",
        "InventoryDT": "Gepeild op",
        "UsableStorageQty": "Bruikbaar",
        "InventoryQty": "Peiling",
        "AvailableQty": "Beschikbaar",
        "Name": "Naam",
        "PlannedDeliveryQty": "Gepland",
        "PlannedQty": "Gepland",
        "planned": "Gepland",
        "start": "Start voorraad",
        "ConfirmedQty": "Bevestigd",
        "Confirmed15Qty": "Bevestigd(15g)",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Gelost",
        "confirmed_volume": "Geladen",
        "total_load": "Totaal geladen",
        "Material": "Materiaal",
        "material": "Materiaal",
        "CurrentQty": "Op truck",
        "Site": "Locatie",
        "driver_remarks": "Opmerkingen chauffeur",
        "load_id": "Laad id",
        "odometer": "Kilometerstand",
        "Temperature": "Temperatuur",
        "Density": "Dichtheid",
        "confirmed15Qty": "Geladen (15gr)",
        "confirmed_weight": "Bevestigd gewicht",
        "StartInventoryQty": "Start voorraad",
        "AvailableStorageQty": "Max voorraad",
        "SiteName": "Locatie",
        "Compartments": "Compartimenten",
        "remarks": "Opmerkingen",
        "MaterialSeller": "Verkoper",
        "TicketNb": "Losbon nummer",
        "StartsWith": "Start",
        "Loaded": "Laden",
        "PlannedorConfirmed": "Lossen",
        "Corrected": "Correctie",
        "LeftOver": "Restant",
        "currentTruckInventory": "Op Truck",
        "plannedOrConfirmedForSelected": "Gepland",
        "plannedOrConfirmed": "Lossen",
        "PlannedOrConfirmed": "Lossen",
        "load_id_short": "LoadID",
        "tankID": "Tank",
        "ATG_CurrentQty": "ATG inhoud",
        "ATG_Ullage": "ATG ruimte",
        "OrderQty": "Besteld",
        "ATG_LastReading": "ATG tijd",
        "AverageSalesQty": "Gem verkoop",
        "RunOutDate": "Runout",
        "RealRunOutDate": "Real runout",
        "ReturnableMaterial": "Retour materiaal",
        "ConfirmedReturnQty": "Retour hoeveelheid"
      },
      "print": {
        "cancel": "Annuleren",
        "back": "Terug",
        "title": "Printen",
        "print": "Print ticket",
        "preview": "Voorbeeld",
        "settings": "Instellingen",
        "select_printer": "Selecteer apparaat",
        "margin_top": "Linefeeds vanaf boven",
        "select_a_printer": "Selecteer printer",
        "repeat": "Herhalen",
        "connected_with": "Verbonden met printer",
        "distance": "Afstand onderling"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Extra stops toevoegen",
        "save": "Opslaan",
        "cancel": "Annuleren",
        "delivery_confirmation": "delivery_confirmation",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "material_transfer": "material_transfer",
        "use_access_point": "use_access_point",
        "enable_ocr": "enable_ocr",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "terminal_import_enabled": "terminal_import_enabled",
        "sening_enabled": "sening_enabled",
        "inventory_in_percentage": "inventory_in_percentage",
        "scanner_enabled": "Scanner gebruiken",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "authorize_trip_stop": "authorize_trip_stop",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "use_material_calculation": "use_material_calculation",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "change_terminal": "change_terminal",
        "delivery_ticket_filename": "delivery_ticket_filename (backend)",
        "delivery_ticket_template": "delivery_ticket_template (backend)",
        "dip_unplanned_storages": "dip_unplanned_storages",
        "jsreport_via_backend": "jsreport_via_backend (backend)",
        "load_id_1000_for_heating_oil": "load_id_1000_for_heating_oil",
        "meter_coupling_active": "meter_coupling_active",
        "register_site_coordinates": "register_site_coordinates",
        "truck_compartments_order_desc": "truck_compartments_order_desc",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "description_language": "Nederlands",
      "add_language": "Taal toevoegen",
      "description": "Omschrijving",
      "add": "Toevoegen",
      "clear": "Wissen",
      "detail": "Detail",
      "edit_languages": "Taal bewerken",
      "notify": "Bevestiging",
      "satisfactory": "Klacht",
      "truck_is_empty": "Truck is leeg",
      "add_special_activity": "Extra tijd toevoegen",
      "empty_compartments": "Legen",
      "correction": "Balanceren",
      "close": "Sluiten",
      "current_truck_inventory": "Compartimenten",
      "special_activity": {
        "create": "Extra tijd toevoegen"
      },
      "extra_time": "Soort",
      "show_inventory": "Laatste peiling tonen",
      "hidden_translations": "Hidden translations",
      "start_scan": "Start scanning",
      "scan": "Scan",
      "scan_settings": "Scanner",
      "scan_title": "Document scanner",
      "scan_select_scanner": "SSID",
      "scanner_password": "Wachtwoord",
      "scan_cancel": "Annuleren",
      "scan_preview": "Preview",
      "scanner_ssid": "SSID",
      "Load ID": "Laad ID",
      "skip": "Overslaan",
      "get_load_rack": "Laadgevens ophalen",
      "show_unplanned_trip_stop_storages": "Geplande materialen",
      "filter_compartments": "Filter compartimenten",
      "warnings": {
        "title": "Waarschuwing ",
        "are_you_sure": "Zeker weten?"
      },
      "cannot_retrieve_data": "Kan gegevens niet ophalen",
      "cannot_open_file": "Kan bestand niet openen",
      "connection_error": "Verbindings fout",
      "trip-stops": {
        "authorizer_initials": "Initialen",
        "authorizer_name": "Voornaam",
        "authorizer_sur_name": "Achternaam",
        "customer-signature": "Handtekening klant",
        "site_name": "Naam",
        "site_address": "Adres",
        "site_city": "Stad",
        "site_customerid": "Klant ID",
        "site_extsiteid": "Locatie ID",
        "authorize_retail_site": "Klant",
        "misc_data": "Data",
        "truck_id": "Vrachtwagen",
        "trailer_id": "Trailer",
        "driver_id": "Chauffeur",
        "ext_order_id": "Order ID",
        "date_time": "Datum & tijd",
        "post_authorization_disclaimer": "Disclaimer",
        "authorize": "Authoriseer",
        "onconfirm_authorization_disclaimer": " "
      },
      "use_bx": "Gebruik BX",
      "setup_scanner": "Setup scanner",
      "change_truck": "Wissel truck",
      "remarks_for_trip": "Opmerkingen",
      "trip_stops_for_trip": "Geplande stops",
      "adr_report": "ADR Report",
      "brand": "Merk",
      "test_and_connect": "Verbinden",
      "test_jva": "test by Jakko",
      "form_odometer": "Kilometerstand",
      "start_of_trip": "Begin rit",
      "end_of_trip": "Einde rit",
      "tank_filled": " ",
      "started": "Gestart",
      "scan_retry": "Opnieuw",
      "scan_confirm": "Bevestig",
      "update_location": "Wijzig locatie",
      "ocr_validation": "OCR valideren",
      "show_trip_stop_storages": "Geplande tanks",
      "material": "Materiaal",
      "Amount": "Hoeveelheid",
      "no_materials_on_truck": "Geen materiaal op truck",
      "Material_Calculation": "Materialen",
      "stop_process_deliveries": "Annuleer verzenden losgegevens",
      "process_deliveries": "Verzenden losgegevens",
      "alias": " ",
      "domain_unreachable": "Domain unreachable",
      "domain_not_set": "Domain unreachable (web)",
      "configuration_service_error": "Request for configuration returned an error",
      "change_terminal": "Wijzig depot",
      "available_terminals": "Beschikbare depots",
      "selected_terminal": "Geselecteerd depot",
      "select_terminal": "Selecteer depot",
      "please_select_terminal": "Selecteer een depot aan linkerzijde",
      "change_terminal_dialog": {
        "warning": "U wijzigt het depot naar:",
        "warning:": "U wijzigt het depot naar",
        "title": "Weet u het zeker?",
        "title:": "Weet u het zeker?"
      },
      "show_materials": "Toon materialen",
      "problem-atg-sites": {
        "showDetails": "Toon details",
        "hideDetails": "Verberg details"
      },
      "get_deliveries": "Stuur naar meter",
      "stop_get_deliveries": "Stop halen losgegevens",
      "site_load_data": "Tank ATG informatie",
      "storage": {
        "ATG_LastReading": "ATG tijd",
        "ATG_CurrentQty": "ATG inhoud",
        "ATG_Ullage": "ATG ruimte"
      },
      "problem_atg_sites": "Tank ATG waarschuwingen",
      "reorder_trip_stops": "Wijzig volgorde",
      "external_reporting": "Externe rapportage",
      "material_calculation": "Materiaal berekening",
      "current_trailer_inventory": "Compartimenten",
      "return_materials": "Retour materialen",
      "show_return_materials": " ",
      "details_truck": "Truck",
      "show_filtered_materials": "Toon",
      "show_all_materials": "Toon alle",
      "load_as_planned": "Laad zoals gepland",
      "show_filtered_tanks": "Toon",
      "show_all_tanks": "Toon alle",
      "capture": "neem foto",
      "retry": "opnieuw",
      "auth-form": {
        "postdelivery-tss-quantities": "Tanks",
        "delivered-compartment-quantities": "Levering per compartiment"
      },
      "compartment": {
        "loadedQty": "Geladen",
        "confirmedQty": "Bevestigd",
        "label": "Compartiment"
      },
      "reset": "reset",
      "trips-stops": {
        "authorize_delivery": "Authoriseer levering"
      },
      "add_stop_from_order": "Order toevoegen",
      "last_updated": {
        "description": "Laatst bijgewerkt",
        "how_to_refresh": "heropen paneel om te verversen",
        "unknown": "onbekend"
      },
      "noAtgWarnings": "Niets te tonen",
      "trip-stop": {
        "customerSite": {
          "unloadingMaterials": "Levering"
        }
      },
      "finished_shifts": "Afgeronde diensten",
      "unfinished_shifts": "Open diensten",
      "trip-overview": {
        "start_shift_first": "Start de dienst eerst"
      }
    },
    "se": {
      "login": "Logga in",
      "logout": "Logga ut",
      "login_failed": "inloggningen misslyckades",
      "truck": "bil/lastbil",
      "sites": "Platser",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Dra ändringar från servern"
      },
      "username": "Användarnamn",
      "password": "Lösenord",
      "please_sign_in": "Var god logga in",
      "settings": "Inställningar",
      "truck_settings": "Bil inställningar",
      "domain": "Domän",
      "language": "Språk",
      "save": "Spara",
      "shifts": "Skift",
      "finish": "Avsluta",
      "finished": "Avslutad",
      "detail_information": "Detaljer",
      "details_trailer": "Trailer",
      "tanks": "Tankar",
      "fill_it": "Fyll",
      "tank": "Tank",
      "pause": "Pause",
      "start": "Start",
      "restart": "Restart",
      "map": "Karta",
      "trip_stops_for_shift": ".trip_stops_for_shift",
      "trip_stop_storages": "Depå",
      "trip": "Resa",
      "ticket": "Kvitto",
      "report": "Rapport",
      "site": "Plats",
      "details": "Detaljer",
      "planned": "Planerad",
      "remarks": "Anmärkningar",
      "no_remarks": "Inga Anmärkningar",
      "material_on_truck": "Nuvarande lager",
      "select_trip_stop": "Välj trip-stop",
      "actions": "Åtgärder",
      "add_stop_from_site": "Lägg till stopp från platsen",
      "materials": "Material",
      "materials_in_weight": "Material i vikt",
      "save_location_to_site": "Spara aktuell plats",
      "form_incomplete": "Formulären är ogiltliga",
      "view_pictures": "Visa bilder",
      "confirm": "Bekräfta",
      "cancel": "Avbryt",
      "take_picture": "Ta bild",
      "extra_information": "Extra information",
      "use_weight": "Använd vikt",
      "register_at_15_d": "Registrera liter",
      "delivery": "Leverans",
      "delivery_truck_information": "Leveransinfo",
      "storages": "Tankar",
      "add_trip_stop": "Lägg till stopp",
      "load": {
        "title": "Lasta"
      },
      "end_inventory": {
        "title": "Avsluta lagerhantering"
      },
      "edit_truck": {
        "title": "Välj bil"
      },
      "unload": {
        "title": "lasta av",
        "detail_information": "Detaljer"
      },
      "column": {
        "SiteName": ".SiteName",
        "compartiments": "fack",
        "TruckID": "Bil ID",
        "TrailerID": "Trailer ID",
        "ResourceID": "Förare",
        "ExtSiteID": "Site ID",
        "OrderID": "Order ID",
        "ActualInventory": "Faktisk inventering",
        "CurrentInventory": "Nuvarande lager",
        "Telephone": "Telefon",
        "Address": "Adress",
        "delivery_ticket_number": "Leveranskvitto nummer",
        "InventoryDT": "Lager datum",
        "UsableStorageQty": "Max tankkapacitet",
        "InventoryQty": "Nuvarande",
        "AvailableQty": "Tillgänglig",
        "Name": ".Name",
        "PlannedDeliveryQty": "Planerad",
        "PlannedQty": "Planerad",
        "planned": "Planerad",
        "start": "Vid start",
        "ConfirmedQty": "Bekräftad",
        "Confirmed15Qty": "Bekräftad 15d",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Bekräftad",
        "confirmed_volume": "Lastad",
        "total_load": "Totalt lastad",
        "Material": "Material",
        "material": "Material",
        "CurrentQty": "Nuvarande",
        "Site": "Site/plats",
        "driver_remarks": "Förarens kommentarer",
        "load_id": "Last ID",
        "odometer": "Kilometer",
        "Temperature": "Temperatur",
        "Density": "Densitet",
        "confirmed15Qty": "Bekräftad",
        "confirmed_weight": "Bekräftad vikt",
        "StartInventoryQty": "Börja inventeringen",
        "AvailableStorageQty": "Max lager",
        "Compartments": "fack",
        "remarks": "Anmärkningar",
        "MaterialSeller": "Säljare",
        "TicketNb": "Leverans kvitto nummer",
        "StartsWith": "Start",
        "Loaded": "Lastat/lastad",
        "PlannedOrConfirmed": "leverera/levererat",
        "Corrected": "Rättad",
        "LeftOver": "Överbliven",
        "AverageSalesQty": "Genomsnittlig försäljning",
        "RunOutDate": "Utgångsdatum",
        "RealRunOutDate": "verkligt utgångsdatum",
        "currentTruckInventory": "Nuvarande",
        "plannedOrConfirmedForSelected": "Planerad",
        "SiteAvailableQty": "Tillgänglig",
        "TankIsFull": "Tanken är full",
        "DropdownCount": "Nedstigande räkning",
        "transferred": "Överförd",
        "Customer": "Kund",
        "Amount": "Order kvantitet",
        "Date": "Order datum",
        "Distance": "Avstånd",
        "OrderQty": "Beställt",
        "Odometer": ".Odometer",
        "load_id_short": "LoadID",
        "tankID": "Tank"
      },
      "print": {
        "cancel": "Avbokad",
        "back": "Tillbaka",
        "title": "Skriv",
        "print": "Skriv kvitto",
        "preview": "förhandsvisning",
        "settings": "Inställningar",
        "select_printer": "välj enhet",
        "margin_top": "Linefeeds från toppen",
        "repeat": "Upprepa",
        "distance": "Avstånd",
        "select_a_printer": "Välj skrivare",
        "connected_with": "Ansluten till"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Lägger till extra tur stopp",
        "save": "spara",
        "cancel": "annullera",
        "delivery_confirmation": "delivery_confirmation",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "authorize_trip_stop": "authorize_trip_stop",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "scanner_enabled": "Scannern är aktiverad",
        "sening_enabled": "sening_enabled",
        "material_transfer": "material_transfer",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "enable_ocr": "enable_ocr",
        "inventory_in_percentage": "inventory_in_percentage",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "use_access_point": "use_access_point",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "use_material_calculation": "use_material_calculation",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "description_language": "Swedish",
      "description": "Beskrivning",
      "add": "Lägg till",
      "add_language": "Lägg till nytt språk",
      "clear": "Klar",
      "detail": "Detalj",
      "truck_is_empty": "Tom bil",
      "empty_compartments": "Töm fack",
      "correction": "Korrigera fack",
      "notify": "Underrättelse",
      "satisfactory": "Otillfredsställande",
      "add_special_activity": "lägg till speciell aktivitet",
      "extra_time": "Aktivitet",
      "special_activity": {
        "create": "Lägg till speciell aktivitet"
      },
      "": "Details",
      "questions": {
        "title": "Frågor",
        "reason_not_confirmed": "Anledning",
        "save": "lämna"
      },
      "current_truck_inventory": "Inventering bil",
      "close": "Stäng",
      "edit_languages": "Redigera språk",
      "hidden_translations": "Hidden translations",
      "warnings": {
        "are_you_sure": "Är du säker",
        "title": "Varning"
      },
      "cannot_retrieve_data": "Det går inte att hämta data",
      "cannot_open_file": "Kan inte öppna filen",
      "connection_error": "Anslutningsfel",
      "show_inventory": "Senaste inventeringen",
      "trip-stops": {
        "authorizer_name": "Förnamn",
        "authorizer_sur_name": "Efternamn",
        "authorize_retail_site": "Sälj Site",
        "disclaimer_body": "Jag, <Namn> H\xE4rmed intygar att (a) den underjordiska lagertanken eller det separata facket i den tanken som identifierats med numret i den f\xF6rsta kolumnen har testats ordentligt och att den m\xE4ngd v\xE4tskor som anges i den andra kolumnen s\xE4kert kan hysas med tank eller fack och (b) anslutningsslangen \xE4r korrekt och s\xE4kert ansluten till tankens (korrekta)p\xE5fyllnings\xF6ppning.",
        "signature": "Signatur",
        "authorize": "Godkänn",
        "authorizer_initials": "Initialer",
        "confirmation": "Leverans godkännande",
        "confirmed_compartments": "fack",
        "disclaimer": "varning/dementi",
        "site_extsiteid": "Site ID",
        "site_customerid": "Kund ID",
        "site_name": "Site namn",
        "site_address": "Site adress",
        "site_city": "Stad",
        "misc_data": "Data",
        "date_time": "Sista avläsning",
        "truck_id": "Bil ID",
        "trailer_id": "Trailer ID",
        "driver_id": "Förar ID",
        "ext_order_id": "Order ID",
        "authorizer": "auktoriserare",
        "authorization_body": "Återställ"
      },
      "trips-stops": {
        "authorize": "tillstånd",
        "authorize_delivery": "Godkänn leverans"
      },
      "filter_compartments": "Filter fack",
      "scanner_ssid": "SSID",
      "scanner_password": "Lösenord",
      "scan": "Scanna kvitto",
      "start_scan": "Starta scanner",
      "scan_settings": "Scanner val",
      "scan_cancel": "Avbryt",
      "scan_title": "Dokument scanner",
      "scan_select_scanner": "Scanner SSID",
      "scan_preview": "Förhandsvisning",
      "adr_report": "ADR rapport",
      "Load ID": "Lastnings ID",
      "show_unplanned_trip_stop_storages": "Oplanerade matrial",
      "remarks_for_trip": "Anmärkningar",
      "order_remarks": "Anmärkningar",
      "use_bx": "Använd BX",
      "setup_scanner": "Setup scanner",
      "brand": "Märke",
      "test_and_connect": "Anslut",
      "started": "Startad",
      "skip": "Hoppa över",
      "scan_retry": "scanna nytt försök",
      "ocr_validation": "ocr godkännande",
      "add_stop_from_order": "Lägg till stopp från order",
      "change_truck": "Byt bil",
      "trip_stops_for_trip": "Planerade stopp",
      "show_trip_stop_storages": "Planerade material",
      "material_calculation": "Materialberäkning",
      "problem_atg_sites": "Problem ATG sites",
      "problem-atg-sites": {
        "showDetails": "Visa detaljer",
        "hideDetails": "Göm detaljer"
      },
      "current_trailer_inventory": "Trailer inventering",
      "transfer_material": "Flytta material",
      "site_load_data": "ATG information",
      "get_load_rack": "Hämta lastnings rack",
      "load_as_planned": "Lasta som planerat",
      "details_truck": "Bil",
      "inventory": "ATG information",
      "material_transfer": "Materialöverföring",
      "trailer": "Trailer",
      "amount_to_transfer": "Volym som ska överföras",
      "material": "Material",
      "amount": "Nuvarande",
      "orders": "Ordrar",
      "cannotCalculate": "Okänd",
      "selected_order": "Vald order",
      "client": "Kund",
      "order_date": "Order datum",
      "min_order_qty": "Minsta order kvantitet",
      "order_material_name_short": "Material",
      "show_materials": "Visa material",
      "show_compartments": "Visa fack",
      "barrel_count": "Faträkning",
      "no_order_selected": "Ingen order vald",
      "unknown": "Ej tillgänglig",
      "invalid_alias_or_domain": "Ogiltligt alias",
      "pok_delivery_success": "Order tillagd",
      "material_transfer_success": "Material överfört",
      "material_transfer_error": "Fel: Material EJ överfört",
      "form_odometer": "Ange kilometer",
      "start_of_shift": "Börja skiftet",
      "end_of_shift": "Slut på skiftet",
      "start_of_trip": "Starta resa/tur",
      "end_of_trip": "Slut på resa/tur",
      "plugin_not_found": "Hittar ej plugin",
      "no_materials_on_truck": "Inget material på bil",
      "backend_environment": "Service",
      "no_trucks_found_try_another_user_or_host": "Inga lastbilar hittades för den här användaren eller domänen",
      "distance": "Avstånd",
      "error": {
        "info": ".info"
      },
      "scan_confirm": ".scan_confirm",
      "tank_filled": " ",
      "Value": {
        "column": {
          "Odometer": ".Odometer"
        }
      },
      "alias": " ",
      "storage": {
        "ATG_LastReading": "Senast pejling",
        "ATG_CurrentQty": "Nuvarande Lager ",
        "ATG_Ullage": "Tomvolym"
      }
    },
    "en": {
      "login": "Login",
      "logout": "Logout",
      "login_failed": "Login failed",
      "truck": "truck",
      "sites": "Sites",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Pull changes from server"
      },
      "username": "Username",
      "password": "Password",
      "please_sign_in": "Please sign in",
      "settings": "Settings",
      "truck_settings": "Truck settings",
      "domain": "Domain",
      "language": "Language",
      "save": "Save",
      "shifts": "Shifts",
      "finish": "Finish",
      "finished": "Finished",
      "detail_information": "Details",
      "details_trailer": "Trailer",
      "tanks": "tanks",
      "fill_it": "Fill",
      "tank": "tank",
      "pause": "Pause",
      "start": "Start",
      "restart": "Restart",
      "resume": "Resume",
      "map": "Map",
      "trip_stops_for_shift": "Tripstops for selected shift",
      "trip_stop_storages": "Tripstop storages",
      "trip": "Trip",
      "ticket": "Ticket",
      "report": "Report",
      "site": "Site",
      "details": "Details",
      "planned": "Planned",
      "remarks": "Remarks",
      "no_remarks": "No remarks",
      "material_on_truck": "Current inventory",
      "select_trip_stop": "Select trip-stop",
      "actions": "ACTIONS",
      "add_stop_from_site": "Add stop from site",
      "materials": "Materials",
      "materials_in_weight": "Material in weight",
      "save_location_to_site": "Save current location",
      "form_incomplete": "Form invalid",
      "view_pictures": "View pictures",
      "confirm": "confirm",
      "cancel": "cancel",
      "take_picture": "Take picture",
      "extra_information": "Extra information",
      "use_weight": "Use weight",
      "register_at_15_d": "Liters 15deg",
      "delivery": "Tanks",
      "delivery_truck_information": "Delivery truck information",
      "storages": "Tanks",
      "add_trip_stop": "Add trip stop",
      "load": {
        "title": "Load"
      },
      "end_inventory": {
        "title": "End inventory management"
      },
      "edit_truck": {
        "title": "Select truck"
      },
      "unload": {
        "title": "Unload",
        "detail_information": "Details"
      },
      "column": {
        "SiteName": "Site name",
        "compartiments": "Compartments",
        "TruckID": "Truck ID",
        "TrailerID": "Trailer ID",
        "ResourceID": "Driver",
        "ExtSiteID": "Site ID",
        "OrderID": "Order ID",
        "ActualInventory": "Actual inventory",
        "CurrentInventory": "Current inventory",
        "Telephone": "Phone",
        "Address": "Address",
        "delivery_ticket_number": "Delivery ticket number",
        "InventoryDT": "Inventory date",
        "UsableStorageQty": "Capacity",
        "InventoryQty": "Contents start",
        "AvailableQty": "Ullage start",
        "Name": "Naam",
        "PlannedDeliveryQty": "Planned",
        "PlannedQty": "Planned",
        "planned": "Planned",
        "start": "At start",
        "ConfirmedQty": "Confirmed",
        "Confirmed15Qty": "Confirmed 15d",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Confirmed",
        "confirmed_volume": "Loaded",
        "total_load": "Total load",
        "Material": "Material",
        "material": "Material",
        "CurrentQty": "Current",
        "Site": "Site",
        "driver_remarks": "Driver remarks",
        "load_id": "Load id",
        "odometer": "Odometer",
        "Temperature": "Temperature",
        "Density": "Density",
        "confirmed15Qty": "Loaded (15d)",
        "confirmed_weight": "Confirmed weight",
        "StartInventoryQty": "Start inventory",
        "AvailableStorageQty": "Max storage",
        "Compartments": "Compartments",
        "remarks": "Remarks",
        "StartsWith": "Start",
        "Loaded": "Load(ed)",
        "PlannedOrConfirmed": "Deliver(ed)",
        "Corrected": "Corrected",
        "LeftOver": "Leftover",
        "AverageSalesQty": "Avg sales",
        "RunOutDate": "Runout",
        "RealRunOutDate": "Real runout",
        "currentTruckInventory": "Current",
        "plannedOrConfirmedForSelected": "Planned",
        "SiteAvailableQty": "Ullage",
        "MaterialSeller": "Seller",
        "TankIsFull": "Tank is full",
        "DropdownCount": "Dropdown count",
        "transferred": "Product switch",
        "Customer": "Customer",
        "Amount": "Order quantity",
        "Date": "Order date",
        "Distance": "Distance",
        "OrderQty": "Ordered",
        "TicketNb": "Delivery ticket number",
        "PlannedorConfirmed": "Deliver(ed)",
        "ATG_LastReading": "ATG time",
        "ATG_CurrentQty": "ATG qty",
        "ATG_Ullage": "ATG ullage",
        "tankID": "Tank",
        "load_id_short": "LoadID",
        "tank-on-site": "Tank",
        "PreDeliveryPct": "Percentage start",
        "PostDeliveryPct": "Percentage finish",
        "PreDeliveryPressure": "Pressure start",
        "PostDeliveryPressure": "Pressure finish",
        "PreDeliveryTemp": "Temp start",
        "PostDeliveryTemp": "Temp finish",
        "startInventoryQty": "Contents start",
        "ReturnableMaterial": "Return material",
        "ConfirmedReturnQty": "Return quantity"
      },
      "print": {
        "cancel": "Cancel",
        "back": "Back",
        "title": "Print",
        "print": "Print ticket",
        "preview": "Preview",
        "settings": "Settings",
        "select_printer": "Select device",
        "margin_top": "Linefeeds from top",
        "repeat": "Repeat",
        "distance": "Distance",
        "select_a_printer": "Select a printer",
        "connected_with": "Connected with"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "TRUCK SETTINGS",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Adding extra trip stops",
        "save": "Save",
        "cancel": "Cancel",
        "delivery_confirmation": "delivery_confirmation",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "authorize_trip_stop": "authorize_trip_stop",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "scanner_enabled": "Scanner enabled",
        "sening_enabled": "sening_enabled",
        "material_transfer": "material_transfer",
        "inventory_in_percentage": "inventory_in_percentage",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "enable_ocr": "enable_ocr",
        "use_access_point": "use_access_point",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "use_material_calculation": "use_material_calculation",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "delivery_ticket_filename": "delivery_ticket_filename (backend)",
        "change_terminal": "change_terminal",
        "DELIVERY_TICKET_FILENAME": "DELIVERY_TICKET_FILENAME",
        "delivery_ticket_template": "delivery_ticket_template (backend)",
        "dip_unplanned_storages": "dip_unplanned_storages",
        "jsreport_via_backend": "jsreport_via_backend (backend)",
        "load_id_1000_for_heating_oil": "load_id_1000_for_heating_oil",
        "meter_coupling_active": "meter_coupling_active",
        "register_site_coordinates": "register_site_coordinates",
        "truck_compartments_order_desc": "truck_compartments_order_desc",
        "barrel_count_enabled": "barrel_count_enabled",
        "drop_down_count_enabled": "drop_down_count_enabled",
        "load_as_planned_enabled": "load_as_planned_enabled",
        "tank_is_full_enabled": "tank_is_full_enabled",
        "temp_density_ltr15_delivery_enabled": "temp_density_ltr15_delivery_enabled",
        "temp_density_ltr15_load_enabled": "temp_density_ltr15_load_enabled",
        "default_compartment_filter_strategy": "default_compartment_filter_strategy",
        "ext_invoice_debtor_id_999_for_heating_oil": "ext_invoice_debtor_id_999_for_heating_oil",
        "authorize_trip_stop_driver_signature": "authorize_trip_stop_driver_signature",
        "customer_type_with_flow_meter": "customer_type_with_flow_meter",
        "customer_type_to_filter_orders": "customer_type_to_filter_orders",
        "deprecated_source_adr_report": "deprecated_source_adr_report",
        "deprecated_source_driver_report": "deprecated_source_driver_report",
        "deprecated_source_trip_report": "deprecated_source_trip_report",
        "deprecated_source_trip_stop_report": "deprecated_source_trip_stop_report",
        "derive_delivery_tank_15qty": "derive_delivery_tank_15qty",
        "dischargeplan_on_unload": "dischargeplan_on_unload",
        "hide_planned_qty_on_delivery": "hide_planned_qty_on_delivery",
        "postdip_enabled": "postdip_enabled",
        "postdip_validation_enabled": "postdip_validation_enabled",
        "predip_enabled": "predip_enabled",
        "restrict_delivery_tickets_for_customer_type": "restrict_delivery_tickets_for_customer_type",
        "show_planned_materials": "show_planned_materials",
        "date_time_format": "date_time_format",
        "meter_connection_address": "meter_connection_address",
        "meter_connection_type": "meter_connection_type",
        "trip_stop_reordering_enabled": "trip_stop_reordering_enabled",
        "unit_of_calculation": "unit_of_calculation",
        "show_temperature_on_unload": "show_temperature_on_unload",
        "show_temperature_on_load": "show_temperature_on_load",
        "show_standard_liters_on_unload": "show_standard_liters_on_unload",
        "show_standard_liters_on_load": "show_standard_liters_on_load",
        "show_ambient_liters_on_unload": "show_ambient_liters_on_unload",
        "show_ambient_liters_on_load": "show_ambient_liters_on_load",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        },
        "customer_notification_on_arrival_enabled": "customer_notification_on_arrival_enabled",
        "customer_notification_on_departure_enabled": "customer_notification_on_departure_enabled",
        "post_authorize_unload": "post_authorize_unload",
        "post_delivery_pct_enabled": "post_delivery_pct_enabled",
        "post_delivery_pressure_enabled": "post_delivery_pressure_enabled",
        "post_delivery_temp_enabled": "post_delivery_temp_enabled",
        "pre_authorize_unload": "pre_authorize_unload",
        "pre_delivery_pct_enabled": "pre_delivery_pct_enabled",
        "pre_delivery_pressure_enabled": "pre_delivery_pressure_enabled",
        "pre_delivery_temp_enabled": "pre_delivery_temp_enabled",
        "pre_delivery_ullage_validation_enabled": "pre_delivery_ullage_validation_enabled"
      },
      "description_language": "English",
      "description": "Description",
      "add": "Add",
      "add_language": "Add new language",
      "clear": "Clear",
      "detail": "Detail",
      "truck_is_empty": "Empty truck",
      "empty_compartments": "Empty compartments",
      "correction": "Correct by compartments",
      "notify": "Notification",
      "satisfactory": "Unsatisfactory",
      "add_special_activity": "Add special activity",
      "extra_time": "Activity",
      "special_activity": {
        "create": "Add special activity"
      },
      "": "Details",
      "questions": {
        "title": "Questions",
        "reason_not_confirmed": "Reason",
        "save": "Submit"
      },
      "current_truck_inventory": "Truck inventory",
      "close": "Close",
      "edit_languages": "Edit language",
      "hidden_translations": "Hidden translations",
      "warnings": {
        "are_you_sure": "Are you sure?",
        "title": "Warning"
      },
      "cannot_retrieve_data": "Cannot retrieve data",
      "cannot_open_file": "Cannot open file",
      "connection_error": "Connection error",
      "show_inventory": "Last inventory",
      "trip-stops": {
        "authorizer_name": "First name",
        "authorizer_sur_name": "Last name",
        "authorize_retail_site": "Retail site",
        "disclaimer_body": "I, <Name> Hereby certify that  (a) the underground storage tank or separate compartment of that tank identified by the number in the first column has been properly tested and that the quantity of liquids specified in the second column can be safely accommodated in that tank or compartment and  (b) the connecting hose is properly and securely connected to the (correct) filling opening of that tank.",
        "signature": "Signature",
        "authorize": "Authorise",
        "authorizer_initials": "Initials",
        "confirmation": "Delivery authorisation",
        "confirmed_compartments": "Compartments",
        "disclaimer": "Disclaimer",
        "site_extsiteid": "SiteID",
        "site_customerid": "CustomerID",
        "site_name": "Site name",
        "site_address": "Site address",
        "site_city": "City",
        "misc_data": "Data",
        "date_time": "Last reading",
        "truck_id": "TruckID",
        "trailer_id": "TrailerID",
        "driver_id": "DriverID",
        "ext_order_id": "OrderID",
        "authorizer": "Authorizer",
        "authorization_body": "Reset",
        "customer-signature": "Customer Signature",
        "driver-signature": "Driver Signature",
        "pre_authorization_disclaimer": "As an authorised representative of the above named company, I hereby certify that (a) the underground storage tank or separate compartment of that tank identified by the number in the first column has been properly tested and that the quantity of liquids specified in the second column can be safely accommodated in that tank or compartment and (b) the connecting hose is properly and securely connected to the (correct) filling opening of that tank.",
        "post_authorization_disclaimer": "As an authorised representative of the above named company, I hereby certify that the above quantity identified by the last column of liquids identified by the first column have been properly delivered.",
        "customer_notifications": "Notifications",
        "onconfirm_authorization_disclaimer": " "
      },
      "trips-stops": {
        "authorize": "Authorisation",
        "authorize_delivery": "Authorize Delivery"
      },
      "filter_compartments": "Filter compartments",
      "scanner_ssid": "SSID",
      "scanner_password": "Password",
      "scan": "Scan ticket",
      "start_scan": "Start scanner",
      "scan_settings": "Scanner options",
      "scan_cancel": "Cancel",
      "scan_title": "Document scanner",
      "scan_select_scanner": "SSID",
      "scan_preview": "Preview",
      "use_bx": "Use BX",
      "setup_scanner": "Setup scanner",
      "brand": "Brand",
      "test_and_connect": "Connect",
      "change_truck": "Change truck",
      "trip_stops_for_trip": "trip stops",
      "remarks_for_trip": "Remarks",
      "material_calculation": "Material calculation",
      "problem_atg_sites": "Tank ATG warnings",
      "problem-atg-sites": {
        "showDetails": "Show details",
        "hideDetails": "Hide details"
      },
      "current_trailer_inventory": "Trailer inventory",
      "transfer_material": "Transfer material",
      "add_stop_from_order": "Add stop from order",
      "site_load_data": "Tank ATG information",
      "skip": "skip",
      "get_load_rack": "Get load rack",
      "show_unplanned_trip_stop_storages": "Unplanned materials",
      "load_as_planned": "Load as planned",
      "show_trip_stop_storages": "Planned materials",
      "details_truck": "Truck",
      "inventory": "ATG information",
      "tank_filled": " ",
      "Load ID": "Load ID",
      "material_transfer": "Material transfer",
      "trailer": "Trailer",
      "amount_to_transfer": "Quantity to transfer",
      "material": "Material",
      "amount": "Current",
      "orders": "Orders",
      "cannotCalculate": "Unknown",
      "selected_order": "Selected order",
      "client": "Customer",
      "order_date": "Order date",
      "min_order_qty": "Min order quantity",
      "order_material_name_short": "Material",
      "order_remarks": "Remarks",
      "show_materials": "Show materials",
      "show_compartments": "Show compartments",
      "adr_report": "ADR Report",
      "barrel_count": "Barrel count",
      "no_order_selected": "No order selected",
      "unknown": "Not available",
      "invalid_alias_or_domain": "Invalid alias",
      "pok_delivery_success": "Order added",
      "material_transfer_success": "Material transferred",
      "material_transfer_error": "Error: Material NOT transferred",
      "form_odometer": "Enter odometer",
      "start_of_shift": "Start of shift",
      "end_of_shift": "End of shift",
      "start_of_trip": "Start of trip",
      "end_of_trip": "End of trip",
      "plugin_not_found": "Plugin not found",
      "no_materials_on_truck": "No material on truck",
      "backend_environment": "service",
      "no_trucks_found_try_another_user_or_host": "No trucks found for this user or domain",
      "ocr_validation": "ocr_validation",
      "scan_retry": "Retry",
      "started": "Started",
      "distance": "Distance",
      "load_more_sites": "Load more sites",
      "not_empty_inventories": "not empty inventories",
      "update_location": "Update location",
      "save_location": "save location",
      "source_compartment_available_quantity": "Source compartment available quantity",
      "target_compartment_free_space": "Target compartment free space",
      "create_site_delivery_success": "Tripstop created",
      "transfer": "Transfer",
      "back_button_not_supported": "Back button not supported",
      "scan_confirm": "Confirm",
      "get_deliveries": "Send to meter",
      "stop_get_deliveries": "Stop getting deliveries",
      "print_tickets": "print tickets",
      "process_deliveries": "Send delivery details",
      "stop_process_deliveries": "Cancel sending delivery details",
      "alias": " ",
      "domain_unreachable": "Domain unreachable",
      "domain_not_set": "Domain unreachable (web)",
      "configuration_service_error": "Request for configuration returned an error",
      "change_terminal": "Change terminal",
      "available_terminals": "Available terminals",
      "select_terminal": "Select terminal",
      "change_terminal_dialog": {
        "title": " ",
        "warning": "Are you sure you want to change terminal to:"
      },
      "delivery_ticket_filename": "delivery_ticket_filename",
      "barrel_count_enabled": "barrel_count_enabled",
      "default_compartment_filter_strategy": "default_compartment_filter_strategy",
      "trip-stop": {
        "dischargePlan": {
          "open": "Discharge Plan",
          "unavailable": "Unavailable, try again in a moment"
        },
        "documents": {
          "dialogText": "Available Documents",
          "dischargeplan": "Incorrect",
          "DischargePlan": "Discharge Plan",
          "DeliveryTicket": "Delivery Ticket",
          "notfound": "No documents available"
        },
        "customer_notification_on_arrival_enabled_yn": "Customer informed on arrival",
        "customer_notification_on_departure_enabled_yn": "Customer informed on departure",
        "customerSite": {
          "unloadingMaterials": "Tanks"
        }
      },
      "trip-stop-storage": {
        "endInventoryQty": "Contents finish",
        "ullage": "PostUllage",
        "postDipUllage": "Ullage finish",
        "MaterialNameShort": "Material",
        "orderQty": "Ordered",
        "plannedQty": "Planned",
        "confirmedQty": "Confirmed",
        "confirmed15Qty": "Confirmed(15)",
        "PreDeliveryPressure": "Pressure start",
        "PostDeliveryPressure": "Pressure finish",
        "PreDeliveryTemp": "Temp start",
        "PostDeliveryTemp": "Temp finish",
        "PreDeliveryPct": "Percentage start",
        "PostDeliveryPct": "Percentage finish"
      },
      "compartment": {
        "label": "Comp",
        "currentQty": "Current",
        "loadedQty": "Loaded",
        "confirmedQty": "Confirmed"
      },
      "storage": {
        "ATG_LastReading": "ATG time",
        "ATG_Current": "ATG Qty",
        "ATG_Ullage": "ATG ullage",
        "ATG_CurrentQty": "ATG contents"
      },
      "TRIP-STOP": {
        "DOCUMENTS": {
          "DISCHARGEPLAN": "Incorrect",
          "DELIVERYTICKET": "Incorrect",
          "DeliveryTicket": "Incorrect",
          "DELIVERYT": "Incorrect"
        }
      },
      "postdip_validation": {
        "title": "Invalid Postdip",
        "warning": "Check the following tanks:",
        "cancel": "Cancel and check data",
        "confirm": "Confirm data"
      },
      "POSTDIP_VALIDATION": {
        "CANCEL": "Cancel and check data",
        "CONFIRM": "Confirm the data"
      },
      "please_select_terminal": "Please select terminal",
      "selected_terminal": "Selected terminal",
      "auth-form": {
        "delivered-compartment-quantities": "Delivery",
        "predelivery-tss-quantities": "Tanks",
        "postdelivery-tss-quantities": "Tanks",
        "postdelivery-tss-quantities-aggregation": "Materials"
      },
      "new_location": "New location",
      "incompatible_backend_version": "Incompatible backend version",
      "remarksz": "remarks",
      "reorder_trip_stops": "Reorder trip stops",
      "reset": "Reset",
      "edit": "Edit",
      "delete": "Delete",
      "external_reporting": "External reporting",
      "return_materials": "Return materials",
      "show_return_materials": " ",
      "show_all_materials": "Show all",
      "show_filtered_materials": "Show",
      "show_filtered_tanks": "Show",
      "show_all_tanks": "Show all",
      "capture": "capture",
      "retry": "retry",
      "last_updated": {
        "description": "Last updated",
        "how_to_refresh": "reopen panel to refresh",
        "unknown": "unknown"
      },
      "noAtgWarnings": "Nothing to show",
      "unfinished_shifts": "Unfinished shifts",
      "finished_shifts": "Finished shifts",
      "trip-overview": {
        "start_shift_first": "Start the shift first"
      }
    },
    "fi": {
      "description_language": "Finnish",
      "shifts": "Vuorot",
      "logout": "Kirjaudu ulos",
      "settings": "Asetukset",
      "edit_truck": {
        "title": "Valitse auto"
      },
      "start": "Aloita",
      "truck": "Auto",
      "language": "Kieli",
      "save": "Tallenna",
      "map": "Kartta",
      "adr_report": "ADR",
      "hidden_translations": "Hidden translations",
      "login": "Kirjaudu sisään",
      "login_failed": "Kirjautuminen epäonnistui",
      "fill_it": "Täyttyä",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Hae muutokset palvelimelta"
      },
      "trip_stop_storages": "Asiakkaiden säiliöt",
      "username": "Käyttäjänimi",
      "password": "Salasana",
      "please_sign_in": "Kirjaudu sisään",
      "domain": "Verkkotunnus",
      "finish": "Lopeta",
      "finished": "Lopetettu",
      "truck_settings": "Auton asetukset",
      "detail_information": "Yhteystiedot",
      "details_trailer": "Perävaunu",
      "tanks": "Säiliöt",
      "tank": "Säiliö",
      "pause": "Tauko",
      "restart": "Aloita uudelleen",
      "trip_stops_for_shift": "Vuoron asiakkaat",
      "trip": "Kuorma",
      "ticket": "POK-rahtikirja",
      "report": "Lastauskortti",
      "site": "Asiakas",
      "sites": "Asiakkaat",
      "details": "Yhteystiedot",
      "planned": "Suunniteltu",
      "remarks": "Huomioitavaa",
      "no_remarks": "Ei huomioitavaa",
      "material_on_truck": "Tuotemäärät",
      "select_trip_stop": "Valitse asiakas",
      "actions": "Toiminnot",
      "add_stop_from_site": "Lisää asema",
      "materials": "Tuotteet",
      "materials_in_weight": "Tuotteiden paino",
      "save_location_to_site": "Tallenna sijainti",
      "form_incomplete": "Muoto on virheellinen",
      "view_pictures": "Katsele kuvia",
      "confirm": "Vahvista",
      "cancel": "Peru",
      "take_picture": "Ota kuva",
      "extra_information": "Lisätiedot",
      "use_weight": "Käytä painoa",
      "register_at_15_d": "Rekisteröi litrat (15)",
      "delivery": "Toimitus",
      "delivery_truck_information": "Toimitustiedot",
      "storages": "Säiliöt",
      "add_trip_stop": "Lisää asiakas",
      "load": {
        "title": "Lastaus"
      },
      "end_inventory": {
        "title": "Loppuvaraston hallinta"
      },
      "unload": {
        "title": "Toimitus",
        "detail_information": "Yhteystiedot"
      },
      "column": {
        "TruckID": "Nuppi",
        "TrailerID": "Perävaunu",
        "ResourceID": "Kuski",
        "ExtSiteID": "Asema",
        "OrderID": "Tilausnumero",
        "ActualInventory": "Todellinen tuotemäärä",
        "CurrentInventory": "Tuotemäärät",
        "Telephone": "Puhelinnumero",
        "Address": "Osoite",
        "delivery_ticket_number": "Toimituslipukkeen numero",
        "InventoryDT": "Viimeisin pintatieto päivä/aika",
        "UsableStorageQty": "Säiliön koko",
        "InventoryQty": "Pintatieto",
        "AvailableQty": "Tyhjä tila",
        "Name": "Nimi",
        "PlannedDeliveryQty": "Suunniteltu",
        "PlannedQty": "Suunniteltu",
        "planned": "Suunniteltu",
        "start": "Alussa",
        "ConfirmedQty": "Vahvistettu",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Vahvistettu",
        "confirmed_volume": "Lastattu",
        "total_load": "Yhteensä",
        "Material": "Tuote",
        "material": "Tuote",
        "CurrentQty": "Tuotemäärä",
        "Site": "Purkupaikat",
        "driver_remarks": "Kuljettajan huomiot",
        "load_id": "Kuormanumero",
        "odometer": "Mittarilukema",
        "Temperature": "Lämpötila",
        "Density": "Tiheys",
        "confirmed15Qty": "Vahvistettu (15)",
        "confirmed_weight": "Vahvistettu paino",
        "StartInventoryQty": "Alkuinventaari",
        "AvailableStorageQty": "Säiliön koko",
        "SiteName": "Aseman nimi",
        "MaterialSeller": "Myyjä",
        "Confirmed15Qty": "Vahvistettu (15)",
        "TicketNb": "Toimituslipukkeen numero",
        "compartiments": "Lokerot",
        "Compartments": "Lokerot",
        "remarks": "Huomioitavaa",
        "Odometer": "Mittarilukema",
        "StartsWith": "Alku",
        "Loaded": "Lastattu",
        "PlannedOrConfirmed": "Toimitettu",
        "Corrected": "Korjattu",
        "LeftOver": "Erotus",
        "AverageSalesQty": "Myynti/vrk",
        "RunOutDate": "Loppu",
        "RealRunOutDate": "Tyhjä",
        "currentTruckInventory": "Tuotemäärä",
        "plannedOrConfirmedForSelected": "Suunniteltu",
        "SiteAvailableQty": "Tyhjä tila",
        "TankIsFull": "Tuli täyteen",
        "DropdownCount": "Pudotusten lkm",
        "transferred": "Tuotemuunnos",
        "Customer": "Asiakas",
        "Amount": "Tilattu määrä",
        "Date": "Toimitettava viimeistään",
        "Distance": "Etäisyys",
        "OrderQty": "Tilattu",
        "load_id_short": "LoadID",
        "tankID": "Säiliö",
        "ATG_CurrentQty": "Tuotemäärä",
        "ATG_Ullage": "Tyhjä tila",
        "ATG_LastReading": "ATG aika"
      },
      "print": {
        "cancel": "Paluu",
        "back": "Takaisin",
        "title": "Tulosta",
        "print": "Tulosta POK-rahtikirja",
        "preview": "Esikatsele",
        "settings": "Asetukset",
        "select_printer": "Valitse laite",
        "margin_top": "Ylämarginaalin rivinvaihto",
        "select_a_printer": "Valitse tulostin",
        "repeat": "Toista",
        "connected_with": "Yhdistetty",
        "distance": "Etäisyys"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Lisäpaikkojen ja lisätilausten ottaminen",
        "save": "Tallenna",
        "cancel": "Paluu",
        "delivery_confirmation": "delivery_confirmation",
        "material_transfer": "material_transfer",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "scanner_enabled": "Skanneri käytössä",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "enable_ocr": "enable_ocr",
        "authorize_trip_stop": "authorize_trip_stop",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "sening_enabled": "sening_enabled",
        "inventory_in_percentage": "inventory_in_percentage",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "register_tank_coordinates": "register_tank_coordinates",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "use_access_point": "use_access_point",
        "show_nesenta_link": "show_nesenta_link",
        "use_nomix_system": "use_nomix_system",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "use_material_calculation": "use_material_calculation",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "clear": "Poista valinnat",
      "show_unplanned_trip_stop_storages": "Näytä muut tuotteet",
      "scan": "Skannaa POK-rahtikirja",
      "warnings": {
        "are_you_sure": "Oletko varma?",
        "title": "Varoitus"
      },
      "remarks_for_trip": "Huomioitavaa",
      "scan_cancel": "Paluu",
      "close": "Sulje",
      "add_language": "Lisää uusi kieli",
      "add": "Lisää",
      "truck_is_empty": "Tyhjennä auto",
      "special_activity": {
        "create": "Lisää muu työ"
      },
      "cannot_retrieve_data": "Tietojen haku epäonnistui",
      "cannot_open_file": "Tiedoston avaus epäonnistui",
      "detail": "Yksityiskohta",
      "trip-stops": {
        "authorizer_name": "Etunimi",
        "authorizer_sur_name": "Sukunimi",
        "disclaimer_body": "Min\xE4, <Nimi> T\xE4ten todistan, ett\xE4 (a) maanalainen s\xE4ili\xF6 tai erillinen kyseisen s\xE4ili\xF6n lokero, joka on identifioitu numerolla ensimm\xE4isess\xE4 sarakkeessa on asianmukaisesti testattu ja ett\xE4 nesteiden m\xE4\xE4r\xE4 m\xE4\xE4ritetty toisessa sarakkeessa voidaan turvallisesti laittaa kyseiseen s\xE4ili\xF6\xF6n tai lokeroon ja (b) yhdistett\xE4v\xE4 letku on asianmukaisesti ja turvallisesti kytketty oikeaan kyseisen s\xE4ili\xF6n t\xE4ytt\xF6aukkoon.",
        "confirmation": "Toimitusvaltuutus",
        "confirmed_compartments": "Lokerot",
        "disclaimer": "Vastuuvapauslauseke",
        "site_extsiteid": "Aseman tunnus",
        "site_customerid": "Asiakkaan tunnus",
        "site_name": "Aseman nimi",
        "site_address": "Aseman osoite",
        "site_city": "Kaupunki",
        "misc_data": "Tiedot",
        "truck_id": "Nuppi",
        "trailer_id": "Perävaunu",
        "driver_id": "Kuljettaja",
        "authorization_body": "Tyhjää",
        "authorizer_initials": "Nimikirjaimet",
        "authorize_retail_site": "Huoltoasema",
        "signature": "Allekirjoitus",
        "authorize": "Valtuuta",
        "date_time": "Viimeisin pintatieto",
        "ext_order_id": "Tilausnumero",
        "authorizer": "Valtuuttaja"
      },
      "setup_scanner": "Skannerin asetukset",
      "brand": "Brändi",
      "scanner_ssid": "SSID-tunnus",
      "scanner_password": "Salasana",
      "test_and_connect": "Yhdistä",
      "started": "Aloitettu",
      "scan_title": "Skannaa asiakirja",
      "scan_retry": "Skannaa uudelleen",
      "ocr_validation": "OCR:n validointi",
      "trip_stops_for_trip": "Suunnitellut purkupaikat",
      "empty_compartments": "Tyhjennä lohkot",
      "correction": "Korjaa lohkoittain",
      "notify": "Ilmoitus",
      "questions": {
        "title": "Kysymykset",
        "save": "Lähetä",
        "reason_not_confirmed": "Syy"
      },
      "current_truck_inventory": "Nupin määrät",
      "scan_settings": "Skannausasetukset",
      "scan_select_scanner": "SSID",
      "error": {
        "info": "Virheilmoitus"
      },
      "scan_confirm": "Vahvista skannaus",
      "material_calculation": "Saldolaskenta",
      "problem-atg-sites": {
        "hideDetails": "Kätke tiedot",
        "showDetails": "Näytä lisätietoja"
      },
      "get_load_rack": "Hae lastaustiedot",
      "load_as_planned": "Hae suunnitellut määrät",
      "details_truck": "Nuppi",
      "material_transfer": "Siirtokuormaus",
      "trailer": "Perävaunu",
      "amount_to_transfer": "Siirrettävä määrä",
      "orders": "Tilaukset",
      "cannotCalculate": "Tuntematon",
      "min_order_qty": "Tilausmäärä",
      "order_material_name_short": "Tuote",
      "show_compartments": "Ei tilausta valittuna",
      "unknown": "Ei saatavilla",
      "invalid_alias_or_domain": "Väärä tunnus",
      "material_transfer_success": "Tuotesiirto tehty",
      "material_transfer_error": "Virhe: Tuotesiirtoa EI tapahtunut",
      "start_of_shift": "Vuoron aloitus",
      "end_of_shift": "Vuoron lopetus",
      "start_of_trip": "Kuorman aloitus",
      "plugin_not_found": "Laajennusta ei löydy",
      "no_trucks_found_try_another_user_or_host": "Ei autoja saatavilla tällä käyttäjällä tai verkkotunnuksella",
      "add_special_activity": "Lisää muu työ",
      "Load ID": "Kuormanumero",
      "show_inventory": "Viimeisin pintatieto",
      "order_remarks": "Huomioitavaa",
      "description": "Kuvaus",
      "edit_languages": "Muokkaa kieltä",
      "extra_time": "Toiminto",
      "connection_error": "Yhteysvirhe",
      "use_bx": "Käytä BX:ää",
      "skip": "Ohita",
      "add_stop_from_order": "Lisää POK-tilaus",
      "change_truck": "Vaihda auto",
      "show_trip_stop_storages": "Näytä lastattavat tuotteet",
      "satisfactory": "Viesti suunnitteluun",
      "trips-stops": {
        "authorize": "Valtuutus",
        "authorize_delivery": "Valtuuta toimitus"
      },
      "filter_compartments": "Suodata lohkot",
      "start_scan": "Aloita skannaus",
      "scan_preview": "Esikatselu",
      "problem_atg_sites": "Ongelma-asemat",
      "current_trailer_inventory": "Perävaunun määrät",
      "transfer_material": "Tee siirtokuormaus",
      "site_load_data": "Pintatiedot",
      "inventory": "Pintatiedot",
      "material": "Tuote",
      "amount": "Tuotemäärä",
      "selected_order": "Valittu tilaus",
      "client": "Asiakas",
      "order_date": "Toimitettava viimeistään",
      "show_materials": "Näytä lohkot",
      "barrel_count": "Tynnyrit",
      "no_order_selected": "Ei tilausta valittuna",
      "pok_delivery_success": "POK-tilaus lisätty",
      "form_odometer": "Syötä mittarilukema",
      "end_of_trip": "Kuorman lopetus",
      "no_materials_on_truck": "Autossa ei tuotteita",
      "distance": "Etäisyys",
      "tank_filled": "Tuli täyteen",
      "transfer": "Siirrä",
      "target_compartment_free_space": "Vastaanottavan lohkon vapaa tila",
      "source_compartment_available_quantity": "Lohkossa oleva siirrettävä määrä",
      "update_location": "Tallenna säiliön koordinaatit",
      "driver_added_stop": {
        "site": "Otettu lisäpaikaksi",
        "order": "Otettu lisäpaikaksi"
      },
      "alias": " ",
      "external_reporting": "Vikailmoitus",
      "storage": {
        "ATG_Ullage": "Tyhjä tila",
        "ATG_CurrentQty": "Tuotemäärä",
        "ATG_LastReading": "ATG aika"
      },
      "show_filtered_materials": "Lastattavat tuotteet",
      "show_all_materials": "Muut tuotteet",
      "show_filtered_tanks": "Näytä",
      "show_all_tanks": "Näytä kaikki",
      "noAtgWarnings": "Ei mitään näytettävää",
      "last_updated": {
        "description": "Viimeksi päivitetty",
        "how_to_refresh": "avaa paneeli uudelleen päivittääksesi",
        "unknown": "tuntematon"
      },
      "trip-stop": {
        "customerSite": {
          "unloadingMaterials": "Toimitus"
        }
      }
    },
    "no": {
      "login": "Logg Inn",
      "logout": "Logg ut",
      "login_failed": "Login failed",
      "truck": "Bil",
      "sites": "Plasser",
      "sync": {
        "warning": "There are unsaved loads, unloads, and/or truck positions, these will be lost. Are you sure to proceed?",
        "title": "Hent endrei"
      },
      "username": "Brukernavn",
      "password": "Passord",
      "please_sign_in": "Logg inn",
      "settings": "Instillinger",
      "truck_settings": "Detaljer",
      "domain": ".domain",
      "language": "Spåk",
      "save": "Lagre",
      "shifts": "Skift",
      "finish": "Avslutt",
      "finished": "Avsluttet",
      "detail_information": "Details",
      "details_trailer": "Henger",
      "tanks": "Tanker",
      "fill_it": "Fyll",
      "tank": "Tank",
      "pause": "Pause",
      "start": "Start",
      "restart": "Restart",
      "map": "Kart",
      "trip_stops_for_shift": ".trip_stops_for_shift",
      "trip_stop_storages": ".trip_stop_storages",
      "trip": "Tur",
      "ticket": "Kvittering",
      "report": "Rapport",
      "site": "Leveringssted",
      "details": "Detaljer",
      "planned": "Planlagt",
      "remarks": "Merknad",
      "no_remarks": "Ingen merknader",
      "material_on_truck": "Lagerbeholdning",
      "select_trip_stop": "Velg",
      "actions": ".actions",
      "add_stop_from_site": "Manuel Ordre",
      "materials": "Produkt",
      "materials_in_weight": "Produkt i vekt",
      "save_location_to_site": "Spar gps posisjon",
      "form_incomplete": "Ugyldig format",
      "view_pictures": "Vis bilder",
      "confirm": "Bekfrefe",
      "cancel": "Avbryt",
      "take_picture": "Ta bilde",
      "extra_information": "Ekstra informasjon",
      "use_weight": "Benytt vekt",
      "register_at_15_d": "Registrere liter",
      "delivery": "Leveranse",
      "delivery_truck_information": "Leveringsinformasjon",
      "storages": "Tanker",
      "add_trip_stop": "Last",
      "load": {
        "title": "Load"
      },
      "end_inventory": {
        "title": "Avslutt laghåndtering"
      },
      "edit_truck": {
        "title": "Velg bil"
      },
      "unload": {
        "title": "Levering",
        "detail_information": "Detaljer"
      },
      "column": {
        "SiteName": "Navn",
        "compartiments": "Rom",
        "TruckID": "Bil ID",
        "TrailerID": "Trailer ID",
        "ResourceID": "Sjåfør",
        "ExtSiteID": "Kundenummer",
        "OrderID": "Ordrenummer",
        "ActualInventory": "Tankvolum",
        "CurrentInventory": "Tanknivå",
        "Telephone": "Addresse",
        "Address": "Address",
        "delivery_ticket_number": "Leveransekvittering",
        "InventoryDT": "Peiledato",
        "UsableStorageQty": "Tankvolum",
        "InventoryQty": "Nivå",
        "AvailableQty": "Tilgjengelig",
        "Name": "Planlagt",
        "PlannedDeliveryQty": "Planned",
        "PlannedQty": "Planlagt",
        "planned": "Planlagt",
        "start": "Start",
        "ConfirmedQty": "Bekreftet",
        "Confirmed15Qty": "Bekreftet 15d",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Bekreftet",
        "confirmed_volume": "Lastet",
        "total_load": "Levert",
        "Material": "Produkt",
        "material": "Produkt",
        "CurrentQty": "Nivå",
        "Site": "Leveringssted",
        "driver_remarks": "Kommentar",
        "load_id": "Laste ID",
        "odometer": "Kilometerstand",
        "Temperature": "Temperatur",
        "Density": "Densitet",
        "confirmed15Qty": "Bekreftet",
        "confirmed_weight": "Vekt",
        "StartInventoryQty": "Beholdning",
        "AvailableStorageQty": "Totalt lagring",
        "Compartments": "Rom",
        "remarks": "Merknad",
        "StartsWith": "Start",
        "Loaded": "Lastet",
        "PlannedOrConfirmed": "Levert",
        "Corrected": "Korreksjon",
        "LeftOver": "Rest",
        "AverageSalesQty": "Gjennomsnitt salg",
        "RunOutDate": "Senest levering",
        "RealRunOutDate": "Tomgangsdato",
        "currentTruckInventory": "Nåverende",
        "plannedOrConfirmedForSelected": "Planlagt",
        "SiteAvailableQty": "Tilgjengelig",
        "MaterialSeller": "Selger",
        "TankIsFull": "Full tank",
        "DropdownCount": ".DropdownCount",
        "transferred": "Overført",
        "Customer": "Kunde",
        "Amount": "Ordrevolum",
        "Date": "Ordredato",
        "Distance": "Distanse",
        "OrderQty": "Bestilt",
        "TicketNb": "Leveringsnummer",
        "Odometer": ".Odometer",
        "load_id_short": "LoadID"
      },
      "print": {
        "cancel": "Kanselert",
        "back": "Tilbake",
        "title": ".title",
        "print": ".print",
        "preview": "Forhåndsvisning",
        "settings": "Instillinger",
        "select_printer": "Velg enhet",
        "margin_top": ".margin_top",
        "repeat": ".repeat",
        "distance": "Distanse",
        "select_a_printer": ".select_a_printer",
        "connected_with": "Koble til"
      },
      "truck_setting": {
        "compartments": "compartments",
        "load_by_weight": "load_by_weight",
        "settings": "Truck settings (application names)",
        "register_liters": "register_liters",
        "adding_extra_trip_stops": "Manuel Ordre",
        "save": "Lagre",
        "cancel": "Avbryt",
        "delivery_confirmation": "delivery_confirmation",
        "add_special_activity": "add_special_activity",
        "questionaire": "questionaire",
        "authorize_trip_stop": "authorize_trip_stop",
        "on_load_mandatory_picture": "on_load_mandatory_picture",
        "on_delivery_mandatory_picture": "on_delivery_mandatory_picture",
        "on_end_inventory_mandatory_picture": "on_end_inventory_mandatory_picture",
        "scanner_enabled": "Skanner aktivert",
        "sening_enabled": "sening_enabled",
        "material_transfer": "material_transfer",
        "inventory_in_percentage": "inventory_in_percentage",
        "terminal_import_enabled": "terminal_import_enabled",
        "odometer_on_shift_level": "odometer_on_shift_level",
        "odometer_on_trip_level": "odometer_on_trip_level",
        "load_ticketnb_per_tank": "load_ticketnb_per_tank",
        "delivery_ticketnb_per_tank": "delivery_ticketnb_per_tank",
        "require_driver_selection_for_ocr": "require_driver_selection_for_ocr",
        "add_extra_order": "add_extra_order",
        "add_extra_trip_stops": "add_extra_trip_stops",
        "show_atg_inventory_warnings": "show_atg_inventory_warnings",
        "show_nesenta_link": "show_nesenta_link",
        "register_tank_coordinates": "register_tank_coordinates",
        "use_nomix_system": "use_nomix_system",
        "show_trip_id_in_trip_overview": "show_trip_id_in_trip_overview",
        "enable_ocr": "enable_ocr",
        "use_access_point": "use_access_point",
        "hide_delivery_ticket_input": "hide_delivery_ticket_input",
        "use_material_calculation": "use_material_calculation",
        "on_load_capture": "on_load_capture",
        "on_delivery_capture": "on_delivery_capture",
        "on_end_inventory_capture": "on_end_inventory_capture",
        "on_load_scan": "on_load_scan",
        "on_delivery_scan": "on_delivery_scan",
        "on_end_inventory_scan": "on_end_inventory_scan",
        "show_atg_information": "show_atg_information",
        "adr_report": "adr_report",
        "errors": {
          "unit_of_calculation": "Unit of calculation mismatch input options. Check configuration."
        }
      },
      "description_language": "Norsk",
      "description": "Beskrivelse",
      "add": "Legg til",
      "add_language": "Legg til nytt språk",
      "clear": "Slett",
      "detail": "Detaljer",
      "truck_is_empty": "Tom bil",
      "empty_compartments": "Tomme rom",
      "correction": "Overpuming",
      "notify": "Varsling",
      "satisfactory": "Utilfredsstillende",
      "add_special_activity": "Annet arbeid",
      "extra_time": "Aktivitet",
      "special_activity": {
        "create": "Annet arbeid"
      },
      "": "Details",
      "questions": {
        "title": "Hjelp",
        "reason_not_confirmed": "Årsak",
        "save": "Sammendrag"
      },
      "current_truck_inventory": "Lagerbeholdning bil",
      "close": "Avslutt",
      "edit_languages": "Velg språk",
      "hidden_translations": "Hidden translations",
      "warnings": {
        "are_you_sure": "Er du sikkert",
        "title": "Advarsel"
      },
      "cannot_retrieve_data": "Kan ikke motta data",
      "cannot_open_file": "Kan ikke åpne fil",
      "connection_error": "Tilkoblingsfeil",
      "show_inventory": "Siste peiling",
      "trip-stops": {
        "authorizer_name": "Fornavn",
        "authorizer_sur_name": "Etternavn",
        "authorize_retail_site": ".authorize_retail_site",
        "disclaimer_body": ".disclaimer_body",
        "signature": "Signatur",
        "authorize": "Godkjenn",
        "authorizer_initials": "Initialer",
        "confirmation": "Leveringsgodkjenning",
        "confirmed_compartments": "Rom",
        "disclaimer": ".disclaimer",
        "site_extsiteid": "Kundenummer",
        "site_customerid": "Kundenummer",
        "site_name": "Kundenavn",
        "site_address": "Adresse",
        "site_city": "By",
        "misc_data": "Informasjon",
        "date_time": "Siste peiling",
        "truck_id": "Bil ID",
        "trailer_id": "Henger ID",
        "driver_id": "Sjåfør ID",
        "ext_order_id": "Odre",
        "authorizer": "Godkjenne",
        "authorization_body": "Restart"
      },
      "trips-stops": {
        "authorize": "Godkjenning",
        "authorize_delivery": "Godkjenn leverasnse"
      },
      "filter_compartments": ".filter_compartments",
      "scanner_ssid": "SSID",
      "scanner_password": "Passord",
      "scan": ".scan",
      "start_scan": ".start_scan",
      "scan_settings": ".scan_settings",
      "scan_cancel": "Avbryt",
      "scan_title": ".scan_title",
      "scan_select_scanner": ".scan_select_scanner",
      "scan_preview": "Forhåndsvisning",
      "use_bx": "Lagerbeholdning",
      "setup_scanner": ".setup_scanner",
      "brand": "Mere",
      "test_and_connect": "Koble til",
      "change_truck": "Velg bil",
      "trip_stops_for_trip": "Planlagt stopp",
      "remarks_for_trip": "Merknad",
      "material_calculation": "Ledig kapastiet på tank",
      "problem_atg_sites": ".problem_atg_sites",
      "problem-atg-sites": {
        "showDetails": "Velg detaljer",
        "hideDetails": "Skul detaljer"
      },
      "current_trailer_inventory": "Henger beholdning",
      "transfer_material": "Velg produkt",
      "add_stop_from_order": "Manuel Ordre",
      "site_load_data": "ATG informasjon",
      "skip": "Hopp over",
      "get_load_rack": ".get_load_rack",
      "show_unplanned_trip_stop_storages": ".show_unplanned_trip_stop_storages",
      "load_as_planned": "Planlgat last",
      "show_trip_stop_storages": "Produkt",
      "details_truck": "Bil",
      "inventory": "ATG informasjon",
      "tank_filled": " ",
      "Load ID": "Laste ID",
      "material_transfer": "Produktoverføring",
      "trailer": "Trailer",
      "amount_to_transfer": "Volum til overføring",
      "material": "Produkt",
      "amount": "Nåværende",
      "orders": "Ordrer",
      "cannotCalculate": "Ukjent",
      "selected_order": "Velg order",
      "client": "Kunde",
      "order_date": "Velg dato",
      "min_order_qty": "Minste ordre kvantum",
      "order_material_name_short": "Produkt",
      "order_remarks": "Merknad",
      "show_materials": "Vis produkt",
      "show_compartments": "Velg rom",
      "adr_report": "Adr rapport",
      "barrel_count": "Fatr",
      "no_order_selected": ".no_order_selected",
      "unknown": "Ikke tilgjengelig",
      "invalid_alias_or_domain": "Velg alias",
      "pok_delivery_success": "Odre lagt til",
      "material_transfer_success": "Produkt overført",
      "material_transfer_error": "ERROR: Produkt ikke overført",
      "form_odometer": "Kilometerstand",
      "start_of_shift": "Start skift",
      "end_of_shift": "Avslutt skift",
      "start_of_trip": "Start tur",
      "end_of_trip": "Avslutt tur",
      "plugin_not_found": "Tilkobling ikke funnet",
      "no_materials_on_truck": "Ingen produkt på bil",
      "backend_environment": "Service",
      "no_trucks_found_try_another_user_or_host": "Ingen bil funnet f",
      "ocr_validation": ".ocr_validation",
      "scan_retry": ".scan_retry",
      "started": "Start",
      "distance": "Avstand",
      "error": {
        "info": ".info"
      },
      "scan_confirm": ".scan_confirm",
      "alias": " "
    }
  };
  _exports.default = _default;
});